export const toggleBoolean = (prev: boolean) => !prev;

const isValidArrayIndex = (arr: Array<string>, idx: number) => {
  return !(idx < 0 || idx >= arr.length);
};

export const clearTypenameRecursive = (obj: any): any => {
  if (obj !== null && typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj.map((item) => clearTypenameRecursive(item));
    }

    const newObj: any = {};
    for (const key in obj) {
      if (key !== '__typename') {
        newObj[key] = clearTypenameRecursive(obj[key]);
      }
    }
    return newObj;
  }

  return obj;
};
export const stringifyObjectIds = (object: any) => ({
  ...object,
  _id: String(object._id),
});

export function addValueAtIndex(arr: Array<any>, idx: number, value: string) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    console.error({ arr, idx });

    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr: Array<any>, idx: number, newValue: string) {
  if (!isValidArrayIndex(arr, idx)) {
    console.error({ arr, idx });

    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr: Array<any>, idx: number, updater: any) {
  if (!isValidArrayIndex(arr, idx)) {
    console.error({ arr, idx });

    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr: Array<any>, idx: number) {
  if (!isValidArrayIndex(arr, idx)) {
    console.error({ arr, idx });
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export const isSameDocument = (obj1: any, obj2: any) =>
  Boolean(String(obj1?._id)) && String(obj1?._id) === String(obj2?._id);

export const getDocumentIndex = (objects: any, object: any) => {
  const idx = objects.findIndex((t: any) => isSameDocument(t, object));
  return idx >= 0 ? idx : null;
};
