import { combineReducers } from 'redux';
// slices
import calendarReducer from './slices/calendar';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  calendar: calendarReducer,
});

export { rootReducer };
