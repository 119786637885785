// import React from 'react';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import { useRealmApp } from '../../components/RealmApp';
import { useMemo } from 'react';

export function useApolloClient(silentError = false) {
  const { realmApp, baseUrl, refreshCustomData } = useRealmApp();
  if (!realmApp?.currentUser) {
    if (silentError) {
      console.log(`You must be logged in to Realm to call useApolloClient()`);
    } else {
      throw new Error(`You must be logged in to Realm to call useApolloClient()`);
    }
  }

  return useMemo(() => {
    const graphqlUri = `${baseUrl}/api/client/v2.0/app/${realmApp.id}/graphql`;
    // Local apps should use a local URI!
    // const graphqlUri = `https://us-east-1.aws.stitch.mongodb.com/api/client/v2.0/app/${realmApp.id}/graphql`

    async function getValidAccessToken() {
      // An already logged in user's access token might be expired. We decode the token and check its
      // expiration to find out whether or not their current access token is stale.
      const { exp } = jwt_decode(realmApp.currentUser?.accessToken as string) as any;
      const isExpired = Date.now() >= exp * 1000;
      if (isExpired) {
        // To manually refresh the user's expired access token, we refresh their custom data
        // await realmApp.currentUser?.refreshCustomData();
        console.warn('token expired. refresh requested.');
        await refreshCustomData();
      }
      // The user's access token is now guaranteed to be valid (unless their account is disabled or deleted)
      return realmApp.currentUser?.accessToken;
    }

    return new ApolloClient({
      link: new HttpLink({
        uri: graphqlUri,
        // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
        // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
        // access token before sending the request.
        fetch: async (uri, options: any) => {
          const accessToken = await getValidAccessToken();
          options.headers.Authorization = `Bearer ${accessToken}`;
          return fetch(uri, options);
        },
      }),
      cache: new InMemoryCache(),
    });
  }, [realmApp.id, realmApp.currentUser]);
}
