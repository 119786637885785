export type WriteRule = {
  inputs: WriteFields | (() => WriteFields);
  fields: WriteFields | (() => WriteFields);
};
export type AdvancedWriteRule = {
  insert: WriteRule;
  update: WriteRule;
};

export enum WriteRuleType {
  Insert = 'insert',
  Update = 'update',
}

export type ReadFields = Array<string | [string, any[]]>;
export type WriteFields = Array<[string, string]>;

export type Query = {
  queryTitle: string;
  queryArguments: string;
  readFields: ReadFields;
};
export type Mutation = {
  name: string;
  writeRule: WriteRule;
  readFields: ReadFields;
  query?: string;
};

export type UserRules = {
  [key: string]: {
    reads: {
      [key: string]: ReadFields | (() => ReadFields);
    };
    writes: {
      [key: string]: AdvancedWriteRule | WriteRule;
    };
  };
};
