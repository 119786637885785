import { CustomData } from '../user/customData';
import { UserTypes } from '../user';

export const customDataToUserType = (customDataType?: CustomData): UserTypes => {
  switch (customDataType) {
    case CustomData.MEMBER:
      return UserTypes.MEMBER;
    case CustomData.COACH:
      return UserTypes.COACH;
    case CustomData.ADMIN:
      return UserTypes.ADMIN;
  }
  throw new Error('unknown customData type: ' + customDataType);
};
export const userTypeToCustomData = (userType: UserTypes): CustomData => {
  switch (userType) {
    case UserTypes.MEMBER:
      return CustomData.MEMBER;
    case UserTypes.COACH:
      return CustomData.COACH;
    case UserTypes.ADMIN:
      return CustomData.ADMIN;
  }
  throw new Error('unknown userType: ' + userType);
};
