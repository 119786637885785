// @ts-nocheck
// import React from 'react';
import * as Realm from 'realm-web';
import { useMemo, useEffect, useRef } from 'react';
import { useCollection } from './useCollection';
import { useRealmApp } from '../../components/RealmApp';
import _ from 'lodash';

// By default we won't do anything for a change event unless the caller passed in a change handler
// for the change event type.
const noop = () => {};
const defaultChangeHandlers = {
  onInsert: noop,
  onUpdate: noop,
  onReplace: noop,
  onDelete: noop,
};

/**
 * Opens/manages a change stream and calls provided change handlers for a given MongoDB collection.
 * @param collectionName
 * @param {Object} changeHandlers - A set of callback functions to call in response to changes.
 * @param {(change: Realm.Services.MongoDB.InsertEvent<T>) => void} [changeHandlers.onInsert] - A change handler callback that receives an Insert event.
 * @param {(change: Realm.Services.MongoDB.UpdateEvent<T>) => void} [changeHandlers.onUpdate] - A change handler callback that receives an Update event.
 * @param {(change: Realm.Services.MongoDB.ReplaceEvent<T>) => void} [changeHandlers.onReplace] - A change handler callback that receives a Replace event.
 * @param {(change: Realm.Services.MongoDB.DeleteEvent<T>) => void} [changeHandlers.onDelete] - A change handler callback that receives a Delete event.
 */

const throttleMap = new Map();
const watcherMap = new Map();
const watcherActionMap = new Map<string, Map>();
export function useWatch(
  callerRef: string,
  collectionName: string,
  watcherType: string,
  changeHandlers: {
    onInsert: (change: Realm.Services.MongoDB.InsertEvent<T>) => void;
    onUpdate: (change: Realm.Services.MongoDB.UpdateEvent<T>) => void;
    onReplace: (change: Realm.Services.MongoDB.ReplaceEvent<T>) => void;
    onDelete: (change: Realm.Services.MongoDB.DeleteEvent<T>) => void;
  },
  throttle: number = 0
) {
  const { realmApp, user } = useRealmApp();
  const collection = useCollection({
    cluster: realmApp.dataSourceName,
    db: import.meta.env.VITE_DB_NAME,
    collection: collectionName,
  });
  const filter = useMemo(() => ({}), []);
  const handlers = { ...defaultChangeHandlers, ...changeHandlers };

  // We copy the handlers into a ref so that we can always call the latest version of each handler
  // without causing a re-render when the callbacks change. This can prevent infinite render loops
  // that would otherwise happen if the caller doesn't memoize their change handler functions.
  const handlersRef = useRef(handlers);
  useEffect(() => {
    handlersRef.current = {
      onInsert: handlers.onInsert,
      onUpdate: handlers.onUpdate,
      onReplace: handlers.onReplace,
      onDelete: handlers.onDelete,
    };

    if (watcherActionMap.has(collectionName)) {
      watcherActionMap.get(collectionName).set(callerRef, handlersRef);
    } else {
      const handlerMap = new Map();
      handlerMap.set(callerRef, handlersRef);
      // console.log({ setElse: { callerRef, handlersRef } });
      watcherActionMap.set(collectionName, handlerMap);
    }
    // console.log({ callerRef, watcherMap, watcherActionMap });
  }, [handlers.onInsert, handlers.onUpdate, handlers.onReplace, handlers.onDelete]);
  // Set up a MongoDB change stream that calls the provided change handler callbacks.
  useEffect(() => {
    const watchChanges = async () => {
      console.log(
        'Watching changes for ' + collectionName + ' / ' + callerRef + '/ throttle:' + throttle
      );
      for await (const change of collection.watch({ filter })) {
        console.log(`${watcherType}:${change.operationType}`);
        console.log({ changedDoc: change.fullDocument });
        switch (change.operationType) {
          case 'insert': {
            watcherActionMap.get(collectionName).forEach(({ current }) => current.onInsert(change));
            break;
          }
          case 'update': {
            const componentCallerUpdate = (watcherActionMap, collectionName, change) => {
              watcherActionMap
                .get(collectionName)
                .forEach(({ current }) => current.onUpdate(change));
            };
            if (throttle > 0) {
              const throttler = _.throttle(componentCallerUpdate, throttle);
              throttler(watcherActionMap, collectionName, change);
            } else {
              componentCallerUpdate(watcherActionMap, collectionName, change);
            }

            break;
          }
          case 'replace': {
            watcherActionMap
              .get(collectionName)
              .forEach(({ current }) => current.onReplace(change));
            break;
          }
          case 'delete': {
            watcherActionMap.get(collectionName).forEach(({ current }) => current.onDelete(change));
            break;
          }
          default: {
            // change.operationType will always be one of the specified cases, so we should never hit this default
            throw new Error(`Invalid change operation type: ${change.operationType}`);
          }
        }
      }
    };
    if (user && collection) {
      if (!watcherMap.has(collectionName)) {
        watcherMap.set(collectionName, watchChanges());
      }
    }
    // return () => console.warn('useWatch removed');
  }, [collection, filter, user]);
}
