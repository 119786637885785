export enum Projects {
  ADMIN = 'admin-webapp',
  COACH = 'coach-webapp',
  MEMBER = 'member-webapp',
}

export const databaseMap = {
  aegean: 1,
  aegean2: 2,
  aegean3: 3,
  aegean4: 4,
  aegean5: 5,
};
