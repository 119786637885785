// import React from 'react';
import { gql } from '@apollo/client';
import { getUserIdFieldName, useRealmApp } from '../../../components/RealmApp';
import { useState } from 'react';
import { useApolloClient } from '../useApolloClient';
import {
  UserCustomData,
  UserCustomDataCollections,
  EditUserInput,
  UserCustomDataTitles,
  UserCustomDataUnion,
  UserTypes,
} from '@mymeeinc/types/user';
import useSettings from '../../useSettings';
import watchMany from '../watch/watchMany';
import { AssignCoachFn } from '@mymeeinc/types/user/customData';
import { SmartFetchCacheGetter, useSmartFetch } from '../useSmartFetch';
import uuidv4 from '../../../utils/uuidv4';
import { generateQuery, generateUpdateMutation } from '../../../graphql/utils';
import { getUserReadRule, getUserWriteRule } from './rules';

import _ from 'lodash';
import { singular } from 'pluralize';
import { userTypeToCustomData } from '@mymeeinc/types/user/functions';
type UseUsersHookInput = {
  userType: UserTypes;
  userId?: string;
  callerRef: string;
  autoFetch?: boolean;
};
export type EditUserMethod = (userId: string, formData: EditUserInput) => Promise<void>;
export type EditUserPartialMethod = (
  userId: string,
  formData: Partial<EditUserInput>
) => Promise<void>;

export const useMemberCustomData = (callerRef: string, autoFetch: boolean = true) =>
  useCustomData({
    userType: UserTypes.MEMBER,
    callerRef: `${callerRef}:${uuidv4()}`,
    autoFetch,
  });
export const useCoachCustomData = (callerRef: string, autoFetch: boolean = true) =>
  useCustomData({
    userType: UserTypes.COACH,
    callerRef: `${callerRef}:${uuidv4()}"`,
    autoFetch,
  });
export const useAdminCustomData = (callerRef: string) =>
  useCustomData({
    userType: UserTypes.ADMIN,
    callerRef: `${callerRef}:${uuidv4()}`,
  });

export function useCustomData({ userType, callerRef, autoFetch = true }: UseUsersHookInput): {
  users: UserCustomDataUnion[];
  editUser: EditUserMethod;
  editUserPartial: EditUserPartialMethod;
  assignCoach: AssignCoachFn;
  getCache: SmartFetchCacheGetter;
  isLoading: boolean;
} {
  // Get a graphql client and set up a list of user in state
  const { setIsLoading: _setIsLoading } = useRealmApp();
  const { appUserType } = useSettings();
  const graphql = useApolloClient(true);
  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(true);
  const [users, setUsers] = useState<UserCustomData[]>([]);
  const componentIdentifier = ['useCustomData', userType].join(':');
  const setIsLoading = (identifier: string) => (isLoading: boolean) => {
    setIsLoadingInternal(isLoading);
    return _setIsLoading(identifier)(isLoading);
  };

  const fetchAllUsers = async <UserCustomData>(): Promise<Array<UserCustomData>> => {
    const setLoading = setIsLoading(componentIdentifier + ':fetchAllUsers');
    try {
      setLoading(true);
      const { data } = await graphql.query(
        generateQuery(UserCustomDataTitles[userType], {
          queryTitle: `($${getUserIdFieldName(userType)}: String)`,
          queryArguments: `(query: { ${getUserIdFieldName(userType)}: $${getUserIdFieldName(
            userType
          )} })`,
          readFields: getUserReadRule(appUserType, userType),
        })
      );
      const users = data[UserCustomDataTitles[userType]];
      return users;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    return [];
  };

  const { getCache, invalidateCache } = useSmartFetch(componentIdentifier, {
    autoFetch,
    set: (customDatas: UserCustomData[]) => {
      setUsers(customDatas);
      setIsLoadingInternal(false);
    },
    fetcher: fetchAllUsers,
    watcher: watchMany,
    injector: (users: UserCustomDataUnion[]): UserCustomDataUnion[] => {
      return users.map((content) => {
        return {
          __typename: userTypeToCustomData(userType),
          ...content,
        } as UserCustomDataUnion;
      });
    },
    collectionName: UserCustomDataCollections[userType],
    key: 'all',
    callerRef,
  });

  const editUser = async (user_id: string, user: EditUserInput) => {
    const setLoading = setIsLoading(componentIdentifier + `:editUser:${user_id}`);
    try {
      console.log({
        editUser: { user_id, user },
      });
      setLoading(true);
      const writeRule = getUserWriteRule(appUserType, userType);
      const variables: { [key: string]: any } = {};
      Object.entries(user).map(([key, value]) => {
        // console.log({ key, value });
        if (value === null) {
          const unsetField = `${key}_unset`;
          const isUnsettable = writeRule.fields.find(([key]) => key === unsetField);
          if (isUnsettable) {
            variables[unsetField] = true;
          } else {
            console.warn('null field found without graphql unset field.');
            console.warn({ key, value, unsetField });
          }
        } else {
          variables[key] = value;
        }
      });
      // console.log({ variables, writeRule });

      const mutationResult = await graphql.mutate(
        generateUpdateMutation(
          {
            name: `updateOne${_.upperFirst(_.camelCase(singular(UserCustomDataTitles[userType])))}`,
            writeRule,
            readFields: getUserReadRule(appUserType, userType),
            query: `{ ${getUserIdFieldName(userType)}: $${getUserIdFieldName(userType)} }`,
          },
          {
            [getUserIdFieldName(userType)]: user_id,
            ...variables,
          }
        )
      );
      invalidateCache();

      console.log({ mutationResult });
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const editUserPartial = async (user_id: string, user: Partial<EditUserInput>) => {
    const setLoading = setIsLoading(componentIdentifier + `:editUserPartial:${user_id}`);
    try {
      const writeRule = getUserWriteRule(appUserType, userType);
      const partialWriteRule = {
        ...writeRule,
        fields: writeRule.fields.filter(([fieldName]) => Object.keys(user).includes(fieldName)),
      };

      const readFields = getUserReadRule(appUserType, userType);
      console.log({
        keys: Object.keys(user),
        writeRule,
        partialWriteRule,
        readFields,
        editUserPartial: { user_id, user },
      });
      setLoading(true);

      const mutationResult = await graphql.mutate(
        generateUpdateMutation(
          {
            name: `updateOne${_.upperFirst(_.camelCase(singular(UserCustomDataTitles[userType])))}`,
            writeRule: partialWriteRule,
            readFields: getUserReadRule(appUserType, userType),
            query: `{ ${getUserIdFieldName(userType)}: $${getUserIdFieldName(userType)} }`,
          },
          {
            [getUserIdFieldName(userType)]: user_id,
            ...user,
          }
        )
      );
      invalidateCache();

      console.log({ mutationResult });
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const assignCoach = async (coach_id: string, member_id: string) => {
    const setLoading = setIsLoading(componentIdentifier + `:assignCoach:${coach_id}:${member_id}`);
    try {
      setLoading(true);
      const assignCoachResult = await graphql.mutate({
        mutation: gql`
          mutation assignCoach($coach_id: String!, $member_id: String!) {
            assignCoach(input: { coach_id: $coach_id, member_id: $member_id }) {
              coach_id
              member_id
              success
            }
          }
        `,
        variables: { coach_id, member_id },
      });
      console.log({ assignCoachResult });
      invalidateCache();
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    users: users as UserCustomDataUnion[],
    isLoading: isLoadingInternal,
    editUser,
    editUserPartial,
    assignCoach,
    getCache,
  };
}
