import { Mutation, Query, WriteFields } from '@mymeeinc/types/graphql/rules/types';
import { OperationVariables } from '@apollo/client/core/types';
import { FetchPolicy, gql } from '@apollo/client';
import pluralize, { singular } from 'pluralize';
import { ContentTypes } from '@mymeeinc/types/content';
import { UserCustomDataTitles } from '@mymeeinc/types/user';
import _ from 'lodash';

export const subFieldInjector = (field: string | Array<any>) => {
  if (Array.isArray(field)) {
    const [fieldName, subfields] = field;
    return `${fieldName} {
                      ${subfields.map((subfield: any) => subFieldInjector(subfield)).join('\n')}
                  }`;
  }
  return field;
};

export const gqlMutationInputFields = (inputFields: WriteFields) =>
  inputFields.map(([name, type]) => `$${name}: ${type}\n`);
export const gqlMutationSetFields = (setFields: WriteFields) =>
  setFields.map(([name]) => `${name}: $${name}`).join('\n');

export const generateInsertMutation = (mutation: Mutation, variables: OperationVariables) => {
  const setFields = mutation.writeRule.fields as WriteFields;
  return {
    mutation: gql`
          mutation ${mutation.name}(
          ${gqlMutationInputFields(setFields)}
          ) {
            ${mutation.name}(
              data: {
                ${gqlMutationSetFields(setFields)}
              }
            ) {
                ${mutation.readFields.map(subFieldInjector).join('\n')}
            }
          }
        `,
    variables,
  };
};
export const generateUpdateMutation = (mutation: Mutation, variables: OperationVariables) => {
  const setFields = mutation.writeRule.fields as WriteFields;
  const inputFields = [...setFields, ...(mutation.writeRule.inputs as WriteFields)];
  return {
    mutation: gql`
          mutation ${mutation.name}(
          ${gqlMutationInputFields(inputFields)}
          ) {
            ${mutation.name}(
              query: ${mutation.query}
              set: {
                ${gqlMutationSetFields(setFields)}
              }
            ) {
                ${mutation.readFields.map(subFieldInjector).join('\n')}
            }
          }
        `,
    variables,
  };
};
export const generateQuery = (
  type: ContentTypes | UserCustomDataTitles,
  query: Query,
  variables: OperationVariables = {},
  multiple = true
) => {
  const dataTitle = multiple ? pluralize(_.camelCase(type)) : singular(_.camelCase(type));

  const { queryTitle = '', queryArguments = '', readFields } = query;
  return {
    query: gql`
        query ${queryTitle} {
          ${dataTitle}${queryArguments} {
            ${readFields.map(subFieldInjector).join('\n')}
          }
        }
      `,
    variables,
    fetchPolicy: 'no-cache' as FetchPolicy,
  };
};
