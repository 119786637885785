import { useEffect } from 'react';
import { MEMORY_CACHE_TIME } from '../../config';
import { useRealmApp } from '../../components/RealmApp';
import { WatchInput } from './watch/watchMany';

const cacheMap: { [key: string]: any } = {};

type FetchOneFn = () => Promise<any>;
type FetchManyFn = () => Promise<Array<any>>;
export type SmartFetchCacheFilter = (a: any) => boolean;
export type SmartFetchCacheGetter = (filter: SmartFetchCacheFilter) => any;
type SmartFetchInput = {
  autoFetch?: boolean;
  set: (data: any) => void;
  fetcher: FetchOneFn | FetchManyFn;
  collectionName: string;
  injector?: (a: any) => any;
  watcher: (input: WatchInput) => void;
  key: string;
  callerRef: string;
  throttle?: number;
};
export const useSmartFetch = (
  componentIdentifier: string = '',
  fetchInput?: SmartFetchInput
): { invalidateCache: () => void; getCache: SmartFetchCacheGetter } => {
  const { user, isLoading } = useRealmApp();

  const getCache = (filter: SmartFetchCacheGetter) =>
    new Promise((resolve, reject) => {
      if (!cacheMap[componentIdentifier][key]) {
        // console.log({ cacheMap, componentIdentifier, key });
        resolve(null);
      }
      cacheMap[componentIdentifier][key]
        .then((cache: any) => {
          const result = cache.filter(filter);
          resolve(result.length > 0 ? result.pop() : null);
        })
        .catch((e: any) => reject(e));
    });
  if (!fetchInput) {
    return {
      invalidateCache: () => {
        console.warn('INVALIDATING SmartFetch global cache');
        // console.log({ debouncerMap });
        Object.keys(cacheMap).map((componentId) => {
          // console.log({ componentId, debouncerMap: debouncerMap[componentId] });
          Object.keys(cacheMap[componentId]).map((_key) => {
            console.log('global cache invalidated: ' + componentId + '-' + _key);
            // console.log({ _key, debouncerMap: debouncerMap[componentId][_key] });
            delete cacheMap[componentId][_key];
          });
        });
        console.warn({ debouncerMap: cacheMap });
      },
      getCache,
    };
  }
  const {
    autoFetch = true,
    set,
    fetcher,
    collectionName,
    injector = (a) => a,
    watcher,
    key,
    throttle = 0,
    callerRef,
  } = fetchInput;

  const invalidateCache = () => {
    if (cacheMap[componentIdentifier]) {
      Object.keys(cacheMap[componentIdentifier]).map((key) => {
        console.log('component invalidating cache: ' + componentIdentifier + '-' + key);
        delete cacheMap[componentIdentifier][key];
      });
    }
  };
  useEffect(() => {
    if (user) {
      if (autoFetch) {
        try {
          if (cacheMap[componentIdentifier] && cacheMap[componentIdentifier][key]) {
            cacheMap[componentIdentifier][key].then((servedCache: any) => {
              if (servedCache === undefined) {
                return;
              }
              console.log(
                `Served from cache: ${componentIdentifier} key: ${key} for: ` + callerRef
              );
              set(servedCache);
            });
          } else {
            if (!cacheMap[componentIdentifier]) {
              cacheMap[componentIdentifier] = {};
            }
            console.log(`Fetching starts: ${componentIdentifier} key: ${key} for: ` + callerRef);
            cacheMap[componentIdentifier][key] = fetcher();
            cacheMap[componentIdentifier][key].then((freshData: any) => {
              if (freshData === undefined) {
                return;
              }
              console.log(`Fetch done: ${componentIdentifier} key: ${key} for: ` + callerRef);
              set(freshData);
            });
            setTimeout(() => delete cacheMap[componentIdentifier][key], MEMORY_CACHE_TIME);
          }
        } catch (e) {
          console.error(e);
          console.error('DEBOUNCER FAILED: ' + [componentIdentifier, key].join(':'));
        }
      }
    } else {
      if (!isLoading) {
        console.error('Invalidating cache as user not found');
        invalidateCache();
      }
    }
  }, [user, isLoading, key, autoFetch, componentIdentifier, cacheMap]);

  if (autoFetch) {
    watcher({
      callerRef,
      collectionName,
      config: {
        setter: set,
        injector,
        cacheInvalidator: invalidateCache,
        throttle,
      },
    });
  }
  return {
    invalidateCache,
    getCache,
  };
};
