import { Projects } from '@mymeeinc/types/config';
import {
  getUserTypeFromCustomData,
  userDataFromCustomDataRow,
  useRealmApp,
} from '../../components/RealmApp';
import {
  useAdminCustomData,
  useCoachCustomData,
  useMemberCustomData,
} from './customData/useCustomData';
import { Conversation, Message, Participant, SendMessage } from '@mymeeinc/types/chat';
import { UserCustomDataUnion, UserTypes } from '@mymeeinc/types/user';
import useSettings from '../useSettings';
import { capitalCase } from 'change-case';
import watchMany from './watch/watchMany';
import { useState } from 'react';
import { gql } from '@apollo/client';
import { useApolloClient } from './useApolloClient';
import { WriteFields } from '@mymeeinc/types/graphql/rules/types';
import { gqlMutationInputFields, gqlMutationSetFields } from '../../graphql/utils';
import { GenericResponse } from '@mymeeinc/types/generic';
import { useSmartFetch } from './useSmartFetch';

function objFromArray<Type extends Record<string, any>>(array: Type[], key = 'id') {
  return array.reduce<Record<string, Type>>((accumulator, current) => {
    accumulator[current.user[key]] = current;
    return accumulator;
  }, {});
}

export type ConversationList = {
  byId: Record<string, Conversation>;
  allIds: string[];
};
export type ContactList = {
  byId: Record<string, Participant>;
  allIds: string[];
};
type useChatReturn = {
  isLoading: boolean;
  contacts: ContactList;
  conversations: ConversationList;
  // activeConversationId: null | string;
  sendMessage: (props: SendMessage) => Promise<GenericResponse>;
  setRead: (props: Conversation) => Promise<GenericResponse>;
};

export const useChat = (callerRef = ''): useChatReturn => {
  const { user, userType, setIsLoading: _setIsLoading } = useRealmApp();
  const graphql = useApolloClient(true);
  const { project } = useSettings();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(true);
  const componentIdentifier = ['data/useChat'].join(':');
  const key = 'allMessages';

  const setIsLoading = (identifier: string) => (isLoading: boolean) => {
    setIsLoadingInternal(isLoading);
    return _setIsLoading(identifier)(isLoading);
  };
  const setLoading = setIsLoading(componentIdentifier);
  const fetcher = async () => {
    try {
      setLoading(true);
      const query = gql`
        query getAllMessages {
          messages {
            _id
            attachments
            body
            content_type
            created_at
            read_at
            is_read
            receiver {
              id
              type
            }
            sender {
              id
              type
            }
          }
        }
      `;
      const { data } = await graphql.query({ query });
      const { messages } = data;
      return messages;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { invalidateCache } = useSmartFetch(componentIdentifier, {
    set: (list: Message[]) => {
      setMessages(list || []);
      setLoading(false);
    },
    watcher: watchMany,
    fetcher,
    collectionName: 'messages',
    key,
    callerRef,
  });

  const { users: admins, isLoading: isLoadingAdmins } = useAdminCustomData('data/useChat');
  const { users: coaches, isLoading: isLoadingCoaches } = useCoachCustomData('data/useChat');
  const { users: members, isLoading: isLoadingMember } = useMemberCustomData('data/useChat');
  const isLoading = isLoadingCoaches || isLoadingAdmins || isLoadingMember;
  let people: UserCustomDataUnion[] = [];
  if (project === Projects.ADMIN) {
    people = [
      ...members,
      ...coaches,
      ...admins.filter(({ admin_id }) => admin_id !== user.user_id),
    ];
  } else if (project === Projects.COACH) {
    people = [...members, ...admins];
  } else {
    if (!isLoadingCoaches && coaches.length > 0) {
      people = [...coaches, ...admins];
    }
  }
  const contacts = objFromArray(
    people.map((contact: UserCustomDataUnion) => {
      const _contact = userDataFromCustomDataRow(contact);

      return {
        user: { id: _contact.user_id, type: getUserTypeFromCustomData(contact) },
        name: _contact.displayName + ` (${contact.__typename})`,
        username: _contact.displayName + ` (${contact.__typename})`,
        avatar: _contact.picture,
        // lastActivity: '2023-04-04T11:37:11.579Z',
        status: 'online',
      } as Participant;
    })
  );
  type ExtendedConversation = Conversation & { latestActivity: Date };
  const convos = people
    .map((contact: UserCustomDataUnion) => {
      const _contact = userDataFromCustomDataRow(contact);
      let level = '';
      const _messages = messages.filter(
        ({ sender: { id: senderId }, receiver: { id: receiverId } }) => {
          return [senderId, receiverId].includes(_contact.user_id);
        }
      );
      const latestActivity = _messages.length
        ? _messages.reduce((latest, current) =>
            new Date(current.created_at) > new Date(latest.created_at) ? current : latest
          )
        : null;
      const contactUserType = getUserTypeFromCustomData(_contact);
      if (userType === UserTypes.COACH) {
        if (![UserTypes.MEMBER].includes(contactUserType)) {
          level = ` (${capitalCase(getUserTypeFromCustomData(_contact))})`;
          if (_messages.length === 0) {
            return null;
          }
        }
      } else if (userType === UserTypes.MEMBER) {
        if ([UserTypes.ADMIN].includes(contactUserType)) {
          if (_messages.length === 0) {
            return null;
          }
        }
        if (![UserTypes.COACH].includes(contactUserType)) {
          level = ` (${capitalCase(getUserTypeFromCustomData(_contact))})`;
        } else {
          level = ` (Your Coach)`;
        }
      } else {
        level = ` (${capitalCase(getUserTypeFromCustomData(_contact))})`;
      }
      return {
        user: {
          id: _contact.user_id,
          type: contactUserType,
        },
        latestActivity: new Date(latestActivity?.created_at ?? 0),
        participants: [
          {
            user: {
              id: _contact.user_id,
              type: contactUserType,
            },
            avatar: _contact.picture,
            name: _contact.displayName + level,
            username: _contact.displayName + level,
            status: 'online',
          },
          {
            user: {
              id: user.user_id,
              type: userType,
            },
            name: user.displayName,
            username: user.displayName,
            avatar: user.picture,
            status: 'online',
            // lastActivity: '2023-04-03T10:37:11.579Z',
          },
        ],
        type: 'ONE_TO_ONE',
        unreadCount: _messages.filter(
          ({ receiver: { id }, is_read }) => id === user.user_id && !is_read
        ).length,
        messages: _messages,
      } as ExtendedConversation;
    })
    .filter((conversations) => conversations !== null) as ExtendedConversation[];

  const conversations = objFromArray(
    convos.sort(({ latestActivity: a }, { latestActivity: b }) => {
      return b.getTime() - a.getTime();
    }) as ExtendedConversation[]
  );

  const sendMessage = async ({
    receiver,
    body,
    content_type,
    attachments,
  }: SendMessage): Promise<{ success: boolean; msg: string }> => {
    try {
      invalidateCache();
      setIsLoadingInternal(true);
      const result = await graphql.mutate({
        mutation: gql`
          mutation sendMessage(
            $receiver: MessageReceiverInsertInput!
            $sender: MessageSenderInsertInput!
            $body: String!
            $content_type: String!
            $attachments: [String]!
            $created_at: DateTime!
          ) {
            insertOneMessage(
              data: {
                receiver: $receiver
                sender: $sender
                body: $body
                content_type: $content_type
                attachments: $attachments
                created_at: $created_at
              }
            ) {
              _id
            }
          }
        `,
        variables: {
          receiver,
          sender: { id: user.user_id, type: userType },
          body,
          content_type,
          attachments,
          created_at: new Date(),
        },
      });
      console.log({ result });
      return { success: true, msg: '' };
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
      return { success: false, msg: err.message };
    } finally {
      setIsLoadingInternal(false);
    }
  };
  const setRead = async ({ user: sender }: Conversation): Promise<GenericResponse> => {
    try {
      invalidateCache();
      const fields: WriteFields = [
        ['is_read', 'Boolean!'],
        ['read_at', 'DateTime!'],
      ];
      setIsLoadingInternal(true);
      const result = await graphql.mutate({
        mutation: gql`
          mutation setRead($sender: MessageSenderQueryInput!, ${gqlMutationInputFields(fields)}) {
            updateManyMessages(
              query: { sender: $sender, is_read_exists: false, read_at_exists: false }
              set: { ${gqlMutationSetFields(fields)} }
            ) {
              matchedCount
            }
          }
        `,
        variables: { sender, is_read: true, read_at: new Date() },
      });
      console.log({ matchedCount: { ...result } });
      return { success: true, msg: '' };
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
      return { success: false, msg: err.message };
    } finally {
      setIsLoadingInternal(false);
    }
  };

  return {
    isLoading: isLoading || isLoadingInternal,
    conversations: {
      byId: conversations,
      allIds: Object.keys(conversations),
    },
    contacts: {
      byId: contacts,
      allIds: Object.keys(contacts),
    },
    sendMessage,
    setRead,
  };
};
