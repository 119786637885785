// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT_GUEST = '/guest';
const ROOT_LOGGED_IN = '/';

// ----------------------------------------------------------------------

export const PATH_GUEST = {
  root: ROOT_GUEST,
  login: path(ROOT_GUEST, '/login'),
};

export const PATH_LOGGED_IN = {
  root: ROOT_LOGGED_IN,
  dashboard: path(ROOT_LOGGED_IN, 'dashboard'),
  member: {
    list: path(ROOT_LOGGED_IN, 'member'),
    profile: (id: string) => path(ROOT_LOGGED_IN, `member/${id}/profile`),
    edit: (id: string) => path(ROOT_LOGGED_IN, `member/${id}/edit`),
    tab: (id: string, tab: string) => path(ROOT_LOGGED_IN, `member/${id}/${tab}`),
  },
  user: {
    root: path(ROOT_LOGGED_IN, 'user'),
    account: path(ROOT_LOGGED_IN, 'user/account'),
  },
  acuity: path(ROOT_LOGGED_IN, 'acuity'),
  calendar: path(ROOT_LOGGED_IN, 'calendar'),
  kanban: path(ROOT_LOGGED_IN, 'kanban'),
  permissionDenied: path(ROOT_LOGGED_IN, 'permission-denied'),
  chat: {
    root: path(ROOT_LOGGED_IN, 'chat'),
    new: path(ROOT_LOGGED_IN, 'chat/new'),
    view: (name: string) => path(ROOT_LOGGED_IN, `chat/${name}`),
  },
  mail: {
    root: path(ROOT_LOGGED_IN, 'mail'),
    all: path(ROOT_LOGGED_IN, 'mail/all'),
  },
};
