// components
import { SettingsValueProps } from '@mymeeinc/shared-web/components/settings/type';
import { Projects } from '@mymeeinc/types/config';
import { UserTypes } from '@mymeeinc/types/user';

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  projectName: 'Mymee Coaching Panel',
  environmentPrefix: import.meta.env.MODE !== 'production' ? '[' + import.meta.env.MODE + '] ' : '',
  project: Projects.COACH,
  appUserType: UserTypes.COACH,
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'orange', // 'default' | 'purple' | 'cyan' | 'blue' | 'orange' | 'red';
  themeStretch: false,
};
