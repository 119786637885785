import { MemberCustomDatum, CoachCustomDatum, AdminCustomDatum } from './customData';

export type EditUserInput = Pick<
  UserCustomDataUnion,
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'phone_number'
  | 'address'
  | 'country'
  | 'state'
  | 'nickname'
  | 'city'
  | 'zip_code'
  | 'gender'
  | 'birthdate'
  | 'is_enabled'
  | 'subscription_start_date'
  | 'subscription_end_date'
  | 'acuity_access_token'
>;

export type UserCustomData = CoachCustomDatum | MemberCustomDatum | AdminCustomDatum;
export type UserCustomDataUnion = CoachCustomDatum & MemberCustomDatum & AdminCustomDatum;
export type UserRealmContextData = UserCustomDataUnion & { user_id: string; displayName: string };

export enum UserTypes {
  ADMIN = 'admin',
  COACH = 'coach',
  MEMBER = 'member',
}

export enum UserCustomDataCollections {
  'admin' = 'admin_custom_data',
  'coach' = 'coach_custom_data',
  'member' = 'member_custom_data',
  'triggers' = '',
}

export enum UserCustomDataTitles {
  'admin' = 'adminCustomData',
  'coach' = 'coachCustomData',
  'member' = 'memberCustomData',
}
